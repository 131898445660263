import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <header className="looklook-header">
      <h1>LOOK</h1>
      <h2>LOOK</h2>
      <a
        href="https://www.instagram.com/matthewmiskol/"
        target="_blank"
        className="miskol"
      >
        Matthew Miskol
      </a>
      <a
        href="https://www.instagram.com/ecuasian/"
        target="_blank"
        className="chang"
      >
        Marcos Chang
      </a>
      <a
        href="https://www.instagram.com/boygentleman/?hl=en"
        target="_blank"
        className="fishburn"
      >
        cj fishburn
      </a>
    </header>
    <div className="information">
      <span>2008 S. 1100 E. Salt Lake City</span>
      <span>801-419-0508</span>
      <span>Walk-ins Always Welcome</span>
      <span>Open Noon - 8pm Thursday- Monday</span>
      <span>Closed Tuesday & Wednesday</span>
    </div>
    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
);

export default IndexPage;
